<script>
import DefaultMixin from './mixin'
import {listLotesAll} from '@/domain/leiloes/services'
import {UInput} from "uloc-vue"
import {format} from "date-fns"
import {datePtToEn} from "@/utils/date"
import SlLoading from "components/layout/components/Loading.vue"
import AuLote from "components/leiloes/components/auditorio/components/Lote.vue"
import SlCheckbox from "@/reuse/input/Checkbox.vue";

export default {
  name: 'AuditorioUnificado',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    SlCheckbox,
    AuLote,
    SlLoading,
    UInput
  },
  data () {
    return {
      loading: true,
      data: format(new Date(), 'dd/MM/yyyy'),
      pagination: {
        limit: 100,
        page: 1,
        total: 0
      },
      habilitarControle: true,
      ocultarCancelados: true,
      ocultarEncerrados: false,
      lotes: []
    }
  },
  computed: {
    bindClass () {
      const css = []
      if (!this.habilitarControle) {
        css.push('lotes-sem-controle')
      }
      return css
    }
  },
  mounted () {
    this.load()
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 400)
  },
  beforeDestroy () {
    this.erplayout.erpheader.setMenuModuleToggle(false)
  },
  methods: {
    load () {
      this.loading = true
      listLotesAll(this.pagination.limit, this.pagination.page, '&dataLeilao=' + datePtToEn(this.data))
          .then(response => {
            this.lotes = response.data.result
            this.pagination.total = response.data.total
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Auditório Ao Vivo',
    name: 'AuditorioUnificado'
  }
}
</script>

<template>
  <div class="auditorio-unificado">

    <div class="mtab header">
      <div class="seletor">
        <div class="lbl">Data dos leilões:</div>
        <div class="input">
          <u-input hide-underline v-model="data" v-mask="'##/##/####'" @keydown.enter="load" /> <u-btn @click="load" class="m-l" label="Buscar" />
        </div>
      </div>
      <div class="title">
        <h1>Auditório Unificado</h1>
      </div>
      <div class="flex items-center">
        <div class="flex items-center">
          <span class="font-10 m-r-sm">Habilitar controles:</span>
          <sl-checkbox v-model="habilitarControle" />
        </div>
        <div class="flex items-center m-l">
          <span class="font-10 m-r-sm">Ocultar cancelados:</span>
          <sl-checkbox v-model="ocultarCancelados" />
        </div>
        <div class="flex items-center m-l">
          <span class="font-10 m-r-sm">Ocultar encerrados:</span>
          <sl-checkbox v-model="ocultarEncerrados" />
        </div>
      </div>
      <div class="qtd-leiloes" v-if="!pagination.total">Nenhum leilão</div>
      <div class="qtd-leiloes" v-else>{{ pagination.total }} leilões</div>
    </div>

    <div v-if="!loading" class="lotes" :class="bindClass">
      <au-lote v-for="lote in lotes" :key="lote.id" :lote-prop="lote" :habilitar-controles="habilitarControle" :ocultar-cancelados="ocultarCancelados" :ocultar-encerrados="ocultarEncerrados" />
    </div>
    <div class="loading" v-else>
      <sl-loading />
    </div>

  </div>
</template>

<style src="./assets/auditorio.styl" lang="stylus" />
