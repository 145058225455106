<template>
  <div v-if="test" class="auditorio-unificado-lote" :class="['lote-status-' + lote.status, bindProdutoCard]">
    <div class="lote-info">
      <div class="lote-extra-info">
        <div class="bg-black">
          {{leilao.dataProximoLeilao|formatDate('HH:mm')}}
        </div>
        <div v-if="leilao.instancia === 1" class="bg-dark">Leilão Único</div>
        <div v-if="leilao.instancia === 2" class="bg-purple">2 datas</div>
        <div v-if="leilao.instancia === 3" class="bg-purple">3 datas</div>
        <div v-if="leilao.instancia > 1" class="bg-green">
          <span v-if="leilao.praca === 1">1º leilão</span>
          <span v-if="leilao.praca === 2">2º leilão</span>
          <span v-if="leilao.praca === 3">3º leilão</span>
        </div>
      </div>
      <div class="lote-img" v-if="lote.bem.image" :class="['lote-img-status' + lote.status]"
           :style="{backgroundImage: 'url('+lote.bem.image.min.url || lote.bem.image.url+')'}"></div>
      <div class="lote-img" v-else>
        <div class="noimg h-full w-full flex flex-center items-center justify-center column text-grey-6 no-select">
          <div>
            <u-icon name="image"/>
          </div>
          Sem imagem
        </div>
      </div>
      <div class="lote-desc">
        <div class="num">Lote {{lote.numeroString || lote.numero}}</div>
        <div class="title">{{lote.bem.siteTitulo}}</div>
        <div class="valor">
          <span class="l">Inicial:</span>
          <span class="r">{{valorInicialAtual|moeda}}</span>
        </div>
        <div class="valor">
          <span class="l">Lance:</span> <!-- ({{(Number(lote.totalLances) > -1 ? Number(lote.totalLances) : 0) + (lote.lances && Array.isArray(lote.lances) ? lote.lances.length : 0)}}) -->
          <span class="r" v-if="ultimoLance"><i v-if="ultimoLance.tipo && ultimoLance.tipo === 3" class="fa-duotone fa-robot"/> {{valorAtual|moeda}}</span>
          <span class="r" v-else>-</span>
        </div>
        <div class="valor">
          <span class="l">Autor do lance:</span>
          <div class="r" v-if="ultimoLance">
            {{ultimoLance.autor.apelido}}
          </div>
          <span class="r" v-else>-</span>
        </div>
        <div class="valor">
          <span class="l">Pagamento:</span>
          <div class="r" v-if="ultimoLance">
            <span v-if="ultimoLance.parcelado">Parcelado em {{ultimoLance.parcelas}}x</span><span class="m-l-xs" v-else>à vista</span>
          </div>
          <span class="r" v-else>-</span>
        </div>
      </div>
    </div>
    <div class="lote-commands">
      <div class="lote-status">
        <div class="lote-status-val" :style="'color: ' + colorStatus">{{loteStatusString}}</div>
        <div class="timer" :class="{'tempoEsgotando': percentTimeleft <= 30}"><i class="fa fa-timer m-r-sm" /> {{timerPregaoFormatado}}</div>
      </div>
      <div v-if="habilitarControles" class="btns">
        <div class="btn-status-leilao">
          <btn-status-leilao style="max-width: 160px" @updateStatus="updateStatus" :loading="loadingUpdateStatus"
                             :leilao="leilao" />
        </div>
        <div>
          <e-btn @click="loteStatus(Status.STATUS_EM_PREGAO)" class="btn-lance"><i class="fa-duotone fa-play" /> <span>Tempo</span></e-btn>
        </div>
        <div>
          <e-btn @click="lance(null)" class="btn-lance">
            <span v-if="ultimoLance">+R${{lote.valorIncremento|moeda}}</span>
            <span v-else>R${{valorInicialAtual|moeda}}</span>
          </e-btn>
        </div>
        <div>
          <e-btn @click="$refs.extraBtns.$refs.lanceManual.digitarLance()" class="erp-btn">Lance Manual</e-btn>
        </div>
        <div>
          <e-btn @click="doulhe(1)" class="erp-btn">Dou-lhe Uma</e-btn>
        </div>
        <div>
          <e-btn @click="doulhe(2)" class="erp-btn">Dou-lhe Duas</e-btn>
        </div>
        <div>
          <e-btn @click="loteStatus(Status.STATUS_VENDIDO)" class="erp-btn bg-green"><i class="fa-duotone fa-gavel" /> <span>Vender</span></e-btn>
        </div>
        <div>
          <e-btn @click="loteStatus(Status.STATUS_SEM_LICITANTES)" class="erp-btn bg-red-2">Sem Licitante</e-btn>
        </div>
      </div>
      <div class="text-center m-b" v-if="habilitarControles">
        <span class="m-r-xs font-11">LEIID {{lote.leilao.id}}</span>
        <au-lote-extra-opts
            ref="extraBtns"
            @statusAberto="loteStatus(Status.STATUS_ABERTO_PARA_LANCES)"
            @lanceManual="lanceManual"
            @deletarLance="deletarLance"
            :lote="lote"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LeilaoMixin from "@/mixins/LeilaoMixin"
import * as Status from '@/domain/leiloes/helpers/LoteStatus'
import {STATUS_EM_LEILAO} from '@/domain/leiloes/helpers/LeilaoStatus'
import {atualizaStatusLeilao} from "@/domain/leiloes/services"
import BtnStatusLeilao from "components/leiloes/components/context/BtnStatusLeilao.vue"
import AuLoteExtraOpts from "components/leiloes/components/auditorio/components/LoteExtraOpts.vue";

export default {
  name: 'AuLote',
  components: {
    AuLoteExtraOpts,
    BtnStatusLeilao
  },
  mixins: [LeilaoMixin],
  props: {
    loteProp: {required: true},
    habilitarControles: {
      type: Boolean,
      default: true
    },
    ocultarCancelados: {
      type: Boolean,
      default: true
    },
    ocultarEncerrados: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lote: this.loteProp,
      leilao: this.loteProp.leilao,
      loadingUpdateStatus: false,
      dblkeyLance: 0,
      processandoLance: false,
      processandoDelete: false,
      seguirLeilao: false
    }
  },
  computed: {
    test () {
      if (this.ocultarCancelados && [Status.STATUS_CANCELADO, Status.STATUS_RETIRADO].includes(this.lote.status)) {
        return false
      }
      if (this.ocultarEncerrados && !([Status.STATUS_ABERTO_PARA_LANCES, Status.STATUS_EM_PREGAO, Status.STATUS_HOMOLOGANDO, 10001, 10002].includes(this.lote.status))) {
        return false
      }
      return true
    },
    colorStatus() { // TODO
      if (this.lote.status === null) {
        return '#FFFFFF'
      }
      if (typeof Status.StatusFake[this.lote.status]['color'] !== 'undefined') {
        return Status.StatusFake[this.lote.status]['color']
      }
      return '#FFFFFF'
    },
    Status () {
      return Status
    },
    bindProdutoCard () {
      const css = []
      if ((this.isRobo && (this.leilao.status === STATUS_EM_LEILAO || this.isCronometroSempreAtivo)) || (this.lote && this.leilao.status === STATUS_EM_LEILAO)) {
        if ((this.lote && this.lote.id === this.lote.id) || (this.isRobo && this.isControleSimultaneo)) {
          if (this.isCronometroSempreAtivo && this.leilao.status !== STATUS_EM_LEILAO) {
            css.push('em-pregao somenteCronometro')
          } else {
            css.push('em-pregao')
          }
        }
      }
      css.push('p-s produto-status-' + this.lote.status)
      this.isFechado && css.push('venda-encerrada')
      this.hasNovoLance && css.push('has-novo-lance')
      this.ultimoLance && css.push('com-lance')
      !this.habilitarControles && css.push('sem-controle')
      this.percentTimeleft && this.percentTimeleft < 17 && css.push('timer-17percent')
      this.percentTimeleft && this.percentTimeleft < 34 && this.percentTimeleft > 17 && css.push('timer-33percent')
      return css
    },
  },
  methods: {
    updateStatus (s) {
      console.log('Update status fom ', this.leilao.status, ' to ', s)
      this.loadingUpdateStatus = true
      atualizaStatusLeilao(this.leilao.id, s)
          .then((response) => {
            this.loadingUpdateStatus = false
            this.leilao.status = s
          })
          .catch(error => {
            this.loadingUpdateStatus = false
            this.alertApiError(error)
            console.log(error, error.data)
          })
    },
    loteStatus (s, lote, noConfirm) {
      noConfirm = typeof noConfirm !== 'undefined' ? noConfirm : false
      if (typeof lote === 'undefined') {
        lote = this.lote.id
      }
      this.updateLoteStatus(s, lote)
    },
    updateLoteStatus (s, lote) {
      console.log('Trocando status do lote para ' + s + '...')
      //this.$refs.changeStatus.open('Alterando status do lote')
      this.comunicatorClass.alterarStatusLote(lote, s)
          .then((response) => {
            // this.$refs.changeStatus.close()
            this.$emit('statusLote', response.data.lote)
          })
          .catch(({response}) => {
            // this.$refs.changeStatus.close()
            console.log(response)
            this.alertApiError(response)
          })
    },
    doulhe (n, lote) {
      let s = 'Dou-lhe Uma'
      if (n === 2){
        s = 'Dou-lhe uas'
      }
      if (typeof lote === 'undefined') {
        lote = this.lote.id
      }
      console.log('Trocando status do lote para ' + s + '...')
      this.comunicatorClass.doulhe(lote, n)
          .then((response) => {
          })
          .catch(({response}) => {
            console.log(response)
            this.alertApiError(response)
          })
    },
    lance (valor = null, boleta = null) {
      if (!valor) {
        console.log(this.ultimoLance)
        if (this.ultimoLance) {
          if (this.ultimoLance.arrematante.apelido !== 'PLATEIA') {
            console.log('Último lance com origem online, pressione rapidamente duas vezes a tecla + para cobrir o lance.')
            this.dblkeyLance++
            if (this.dblkeyLance === 1) {
              let self = this
              this.timerKeyLance = setTimeout(function () {
                self.dblkeyLance = 0
              }, 500)
              return
            } else {
              clearTimeout(this.timerKeyLance)
              this.dblkeyLance = 0
            }
          }
        }
      }
      if (this.processandoLance) return

      if (!valor || Number.isNaN(Number(valor))) {
        let inicial = this.lote.valorInicial
        if (this.leilao.praca === 2) {
          inicial = this.lote.valorInicial2
        }
        if (this.leilao.praca === 3) {
          inicial = this.lote.valorInicial3
        }
        valor = this.ultimoLance ? (this.ultimoLance.valor + this.lote.valorIncremento) : (inicial || this.lote.valorIncremento)
      }
      this.processandoLance = true
      this.comunicatorClass.lance(this.lote.id, valor, null, null, boleta)
          .then((response) => {
            // this.focusMe()
            this.processandoLance = false
            // this.$emit('novoLance', response.data.lote, response.data.lance)
          })
          .catch(({response}) => {
            this.processandoLance = false
            console.log(response)
            this.alertApiError(response)
          })
    },
    deletarLance () {
      window.setTimeout(() => {
        this.processandoDelete = false
      }, 3000)

      if (this.processandoDelete) {
        return
      }

      this.processandoDelete = true
      console.log('Deletando último lance...')
      if (!this.ultimoLance) {
        window.alert('Nenhum lance para apagar')
        // this.processandoDelete = false
        return
      }
      let lance = this.ultimoLance
      this.$uloc.dialog({
        title: 'Deletar Último Lance',
        message: 'Você tem certeza que deseja deletar este lance?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        console.log('Deletando lance ' + lance.id + '...')
        this.comunicatorClass.deleteLance(lance.id)
            .then((response) => {
              this.processandoDelete = false
              this.$emit('deletarLance', lance)
            })
            .catch(({response}) => {
              this.processandoDelete = false
              console.log(response)
              this.alertApiError(response)
            })
      }).catch(() => {})
    },
    lanceManual (v, boleta) {
      this.lance(v, boleta)
    }
  }
}
</script>
